import React from "react"
import { AppBar, Box, Container, Toolbar, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Link as RouterLink } from "react-router-dom"

// Styling using MUI's styled utility
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main, // Set to primary color or desired background color
  color: theme.palette.text.link, // Set to primary color or desired background color  
}))

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: '46px', // Reduce or adjust this value as needed
  padding: theme.spacing(0), // Adjust the padding, possibly reduce or remove
  display: 'flex',
  justifyContent: 'center',
}))

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.link,
  textDecoration: "none",
  fontFamily: "monospace",
  fontWeight: 700,
  letterSpacing: ".3rem",
  textAlign: 'center',
}));
const CustomNav = () => {
  return (
    <StyledAppBar position="static">
      <Container maxWidth="xl">
        <StyledToolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <StyledTitle
              variant="h5"
              noWrap
              component={RouterLink}
              to={"/"}
            >
              Rating Bunny
            </StyledTitle>
          </Box>
        </StyledToolbar>
      </Container>
    </StyledAppBar>
  )
}

export default CustomNav
