import React, { useState } from 'react'
import Search from '../components/Search'
import GetSingleImageDim from '../components/GetSingleImageDim'

const Home = () => {
  const [imageDimensions, setImageDimensions] = useState({
    height: 0,
    width: 0,
  })
  const apiver = 'api/v3'
  const singleImg = '/image/tt1075747'

  const handleSingleImageLoad = (h, w) => {
    console.log('handle single got back: ' + h + ', ' + w)
    setImageDimensions({ height: h, width: w })
  }

  console.log(
    'checking imageDim h: ' +
    imageDimensions.height +
    ' w: ' +
    imageDimensions.width,
  )

  return (
     <React.Fragment>
     {/* Load dimensions if not available */}
       {imageDimensions.height === 0 && imageDimensions.width === 0 && (
        <GetSingleImageDim
          imgUrl={`${apiver + singleImg}`}
          onImageLoaded={handleSingleImageLoad}
        />
      )}
      {imageDimensions.height !== 0 && imageDimensions.width !== 0 && (
        <Search imageDimensions={imageDimensions} />
      )}
    </React.Fragment>
  )
}

export default Home
