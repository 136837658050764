import { useState, useEffect, useMemo, Fragment } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import debounce from "lodash/debounce";
import { FormControl, Box, Select } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  select_input: {
    width: "312px",
    height: 50,
    marginLeft: 5,
    marginBottom: "16px",
    [theme.breakpoints.down("1020")]: {
      width: "100%",
    },
    [theme.breakpoints.down("899")]: {
      margin: 5,
      width: "65%",
    },
  },
  autocomplete_container: {
    height: 50,
    width: "312px",
    marginLeft: 5,
    [theme.breakpoints.down("1020")]: {
      width: "100%",
    },
    [theme.breakpoints.down("899")]: {
      width: "65%",
    },
  },
  autotype_select: {
    display: "flex",
    height: 50,
    alignItems: "center",
  },
  customTextField: {
    "& .MuiOutlinedInput-root": {
      padding: "6px !important",
    },
  },
}));

export default function DebouncedSelect(props) {
  const classes = useStyles();
  const {
    fetchOptions,
    autotype,
    textValue,
    onSelectAutotype,
    autoTypeOptions,
    namesuggestions,
    titlesuggestions,
    titleInputProps,
    nameInputProps,
    setLastTitleSelection,
    setIsAppending,
    setShowDisabled,
    setTextValue,
    lastTitleSelection,
    setLastQuery,
  } = props;
  const [loading, setLoading] = useState(false);
  const [optionList, setOptionList] = useState([]);

  useEffect(() => {
    const suggestions =
      autotype === "name" ? namesuggestions : titlesuggestions;
    setOptionList(suggestions);
    setLoading(!suggestions.length === 0);
  }, [namesuggestions, titlesuggestions, autotype]);

  const debouncedFetchOptions = useMemo(
    () =>
      debounce(async (query) => {
        setLoading(true);
        if (query.length >= 3) {
          await fetchOptions(query);
        } else {
          setOptionList([]);
          setLoading(false);
        }
      }, 500),
    [autotype]
  );

  const handleInputChange = (event, value) => {
    debouncedFetchOptions(value);
    setLoading(false);
    if (autotype === "title") {
      setLastTitleSelection({
        primaryTitle: value,
        isModified: false,
        selected: true,
      });
    } else {
      setIsAppending(false);
      setShowDisabled(false);
      setTextValue(value);
    }
    setLastQuery((prevState) => ({ ...prevState, query: value }));
  };

  const onTitleChange = (event) => {
    let value = event.target.value;
    if (
      lastTitleSelection.selected &&
      value !== lastTitleSelection.primaryTitle
    ) {
      setLastTitleSelection((prevState) => ({
        ...prevState,
        primaryTitle: value,
        isModified: true,
      }));
    }
  };

  const onNameChange = (event) => {
    let value = event.target.value;
    setIsAppending(false);
    setShowDisabled(false);
    setTextValue(value);
  };

  return (
    <>
      <FormControl
        variant="outlined"
        size="small"
        className={classes.select_input}
      >
        <Select
          className={classes.autotype_select}
          id="autotype-select"
          value={autotype}
          onChange={(e) => onSelectAutotype(e.target.value)}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          {autoTypeOptions()}
        </Select>
      </FormControl>
      <Box className={classes.autocomplete_container}>
        <Autocomplete
          className={classes.autocomplete}
          getOptionLabel={(option) =>
            autotype !== "title"
              ? option.firstName + " " + option.lastName
              : option.primaryTitle
          }
          options={optionList}
          loading={loading}
          freeSolo
          onInputChange={handleInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              value={
                autotype === "title"
                  ? lastTitleSelection.primaryTitle
                  : textValue
              }
              placeholder={
                autotype === "title"
                  ? titleInputProps?.placeholder
                  : nameInputProps?.placeholder
              }
              onChange={autotype === "title" ? onTitleChange : onNameChange}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
              className={classes.customTextField}
            />
          )}
        />
      </Box>
    </>
  );
}
