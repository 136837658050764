import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import IconButton from '@mui/material/IconButton'

import TwitterIcon from '@mui/icons-material/Twitter'
import FacebookIcon from '@mui/icons-material/Facebook'
import LinkedInIcon from '@mui/icons-material/LinkedIn'

import { styled } from '@mui/material/styles'

const FooterNav = styled('nav')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginBottom: theme.spacing(0),
}))

const FooterLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginLeft: '3px',
  marginRight: '3px',
  marginBottom: '2px',
  textDecoration: 'none',
}))

const IconStyle = styled(IconButton)(({ theme }) => ({
  paddingTop: '6px',
  paddingBottom: '6px',
  '&.twitter': {
    color: '#1DA1F2',
  },
  '&.facebook': {
    color: '#1877F2',
  },
  '&.linkedin': {
    color: '#0A66C2',
  },  
}))

const FooterText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 600, // Make the text slightly bolder for better visibility
}))

export default function Footer(props) {
  const content = {
    copy: '© 2024 ISCS, Inc',
    link1: 'Home',
    link2: 'About',
    link3: 'Contact',
    ...props.content,
  }

  return (
    <footer>
      <Container maxWidth="xl">
        <Box py={1} sx={{ textAlign: 'center', marginTop:'20px' }}>
          <FooterNav>
            <FooterLink
              component={RouterLink}
              to="/"
              underline="none"
              variant="body1"
            >
              {content['link1']}
            </FooterLink>
            <FooterLink
              component={RouterLink}
              to="/about"
              underline="none"
              variant="body1"
            >
              {content['link2']}
            </FooterLink>
            <FooterLink
              component={RouterLink}
              to="/contact"
              underline="none"
              variant="body1"
            >
              {content['link3']}
            </FooterLink>
          </FooterNav>
          <Box mb={3} sx={{ marginBottom: 0 }}>
            <IconStyle className='twitter' aria-label="Twitter">
              <TwitterIcon />
            </IconStyle>
            <IconStyle className="facebook" aria-label="Facebook">
              <FacebookIcon />
            </IconStyle>
            <IconButton className="linkedin" aria-label="LinkedIn">
              <LinkedInIcon />
            </IconButton>
          </Box>
          <FooterText
            color="textSecondary"
            component="p"
            variant="body2"
            gutterBottom={false}
          >
            {content['copy']}
          </FooterText>
        </Box>
      </Container>
    </footer>
  )
}
