import React from 'react'
import { styled } from "@mui/material/styles"
import Button from '@mui/material/Button'

const StyledButton = styled(Button)(({ theme }) => ({
  select_input: {
    width: '160px',
    margin: '5px !important',
  },  
}))

const ShowFilms = (props) => {
  const { onClick, disabled } = props
  return (
    <StyledButton
      size="small"
      variant="contained"
      color="primary"
      onClick={(e) => onClick(e)}
      disabled={disabled}
    >
      Show Films
    </StyledButton>
  )
}

export default ShowFilms
