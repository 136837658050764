import React from "react"
import { styled } from "@mui/material/styles"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import Logo from "../assets/corpLogo.png"

const FullWidthGrid = styled(Grid)(({ theme }) => ({
  width: '100%',
  display: "flex",
  alignItems: "center",    
  justifyContent: "center",
}))

const CenteredGrid = styled(Grid)(({ theme }) => ({
  width: "95%",  
  justifyContent: "center",
  textAlign: "center", 
}))

const CenteredRowGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
}))

const BoxPadding = styled(Box)(({ theme }) => ({
  paddingLeft: "15px",
  paddingRight: "15px", 
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: 10,
  textAlign: "left",
  lineHeight: 1.5,
}))

const AboutInfo = () => {
  return (
      <Grid data-test-id='top grid'
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <CenteredGrid item> 
          <Box >
            <Paper elevation={4} component={FullWidthGrid}>
              <BoxPadding> 
                <CenteredRowGrid container>
                  <Grid item>
                    <Box
                      component="img"
                      sx={{
                        maxHeight: { xs: 127 },
                        maxWidth: { xs: 150 },
                        p: 1,
                      }}
                      alt="ISCS logo"
                      src={Logo}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h5">
                      ISCS, Inc. Background
                    </Typography>
                  </Grid>
                </CenteredRowGrid>

                <StyledTypography
                  variant="subtitle1"
                  color="primary"
                  paragraph={true}
                >
                  After several years using Sun Workstations in medical imaging
                  software in surgical and research environments, the founder of
                  ISCS, Inc. shifted gears and transferred his expertise to the
                  broker dealer industry to leverage these rising new hardware
                  and software game changing paradigms. He specialized in
                  scripting middleware based on open source tools to customize
                  and rewrap commercial products for the financial industry.
                  After Y2K he shifted gears again and began to use his
                  self-taught knowledge of web application development as a full
                  stack engineer building J2EE apps within the JSF framework.
                  These large multitiered environments became more cumbersome to
                  build with all the boilerplate associated with Enterprise
                  Java.
                </StyledTypography>

                <StyledTypography
                  variant="subtitle1"
                  color="primary"
                  paragraph={true}
                >
                  Seeking to simplify the framework he shifted to reactive
                  frameworks such as Play which greatly accelerated development
                  speed. Over time this entry led to development of web services
                  more than server side HTML rendering as the front-end
                  decoupled from the server-side allowing greater focus on newer
                  more performant asynchronous back end persistence layer
                  compared to the legacy RDBMS systems in traditional financial
                  applications. With exposure to other industries, in particular
                  those with large streaming requirements, this hastened the
                  push into functional development, primarily web services. His
                  skills were self-taught and applied in the field responding to
                  new client demands. While not his primary focus, with the
                  decoupling of front and back end roles, he had to acquire
                  increasing familiarity with state of the art frameworks
                  exemplified by social media platforms to add a face to these
                  services.
                </StyledTypography>

                <StyledTypography
                  variant="subtitle1"
                  color="primary"
                  paragraph={true}
                >
                  This application combines attributes of functional web
                  services, asynchronous nosql collections, with a cutting edge
                  front end to serve media content based on IMDB rating within
                  several contexts. With publicly available data, you can filter
                  films spanning greater than a century of cinema by rating,
                  type, and genre. In addition, you can associate your favorite
                  media performers with their work with those attributes as
                  well. Please feel free to share your thoughts, comments or
                  questions if you wish to learn more on the contact page.
                </StyledTypography>
              </BoxPadding>
            </Paper>
          </Box>
        </CenteredGrid>
      </Grid>
  )
}

export default AboutInfo
