import { createTheme } from "@mui/material/styles"

export const getDesignTokens = (mode) => ({
  palette: {
    mode,
    primary: {
      contrastText: "#fff",
      dark: "#1565c0",
      light: "#42a5f5",
      main: "#3f51b5",
    },
/*     primary: {
      main: '#3f51b5',
    },
 */    breakpoints: {
      values: {
         xs: 0,
         sm: 600,
         md: 890,
         lg: 1200,
         xl: 1536,
       },
     },
    background: {
         default: mode === 'light' ? "#fafafa" : '#303030',
         paper: mode === 'light' ? "#fff" : '#424242',
     },
    divider: mode === 'light' ? "rgba(0, 0, 0, 0.12)" : "rgba(255, 255, 255, 0.12)",
    text: {
       primary: mode === 'light' ? "rgba(0, 0, 0, 0.87)" : "#fff",
       secondary: mode === 'light' ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.7)",
       disabled: mode === 'light' ? "rgba(0, 0, 0, 0.38)" : "rgba(255, 255, 255, 0.5)",
       link: mode === 'light' ? "#fff" : "#3f51b5",
    },
    action: {
       active: mode === 'light' ? "rgba(0, 0, 0, 0.54)" : "#fff",
       hover: mode === 'light' ? "rgba(0, 0, 0, 0.04)" : "rgba(255, 255, 255, 0.08)",
       selected: mode === 'light' ? "rgba(0, 0, 0, 0.08)" : "rgba(255, 255, 255, 0.16)",
       disabled: mode === 'light' ? "rgba(0, 0, 0, 0.26)" : "rgba(255, 255, 255, 0.3)",
       disabledBackground: mode === 'light' ? "rgba(0, 0, 0, 0.12)" : "rgba(255, 255, 255, 0.12)",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
})

export const lightTheme = createTheme(getDesignTokens("light"))
export const darkTheme = createTheme(getDesignTokens("dark"))
