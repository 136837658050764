import React from 'react'
import { styled } from "@mui/material/styles"
import Box from '@mui/material/Box'
import CustomSlider from "./CustomSliderStyles"
import Typography from '@mui/material/Typography'

const StyledBox = styled(Box)(({ theme }) => ({
    width: "100%",
    margin: "5px",
    boxSizing: "border-box", // Ensure padding is included in the width
    [theme.breakpoints.down('1020')]: {
      width: "100%",
    },
    [theme.breakpoints.down('899')]: {
      width: "65%",
    },
  }))

const RatingSlider = (props) => {
  const { valueText, marks, curRating, ratingChange } = props

  return (
    <StyledBox id="rating-selector">
      <Typography id="rating-slider">IMDB rating: {+curRating}</Typography>
      <CustomSlider
        aria-labelledby="discrete-slider-custom"
        getAriaValueText={valueText}
        value={+curRating}
        min={0}
        max={10}
        step={0.1}
        marks={marks}
        track="inverted"
        valueLabelDisplay="off"
        onChange={ratingChange}
      />
    </StyledBox>
  )
}

export default RatingSlider
