/* eslint-disable no-unused-vars */
// library imports
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useTheme, makeStyles } from "@mui/styles";
import {
  Checkbox,
  Grid,
  Box,
  Container,
  ListItemText,
  Link,
  MenuItem,
  Typography,
  Button,
  useMediaQuery,
  CardMedia,
  Card,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import StarIcon from "@mui/icons-material/Star";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import InfiniteScroll from "react-infinite-scroll-component";
import { Circles } from "react-loader-spinner";
import "react-toggle/style.css";

// file imports
import ShowFilmsBtn from "./ShowFilmsBtn";
import AdultToggle from "./AdultToggle";
import pkgJson from "../../package.json";
import RatingSlider from "./RatingSlider";
import YearSlider from "./YearSlider";
import VoteSlider from "./VoteSlider";
import DebouncedSelect from "./DebouncedSelect";
import MultiSelectType from "./MultiSelectType";
import { CircleLoader } from "./CircleLoader";

const currentYear = new Date().getFullYear();
const roles = {
  default: {
    genre: ["Action", "Adventure"],
    year: [currentYear - 3, currentYear],
    votes: 50000,
    titleType: ["movie"],
    isAdult: false,
  },
  gamer: {
    genre: ["Fantasy", "Sci-Fi"],
    year: [2005, 2020],
    titleType: ["videoGame"],
  },
  oldies: {
    genre: ["Drama", "Romance"],
    year: [1945, 1965],
    titleType: ["movie", "tvMovie"],
    isAdult: false,
  },
};

const gridCardStyles = makeStyles((theme) => ({
  filterContainer: {
    height: "340px",
    [theme.breakpoints.down("955")]: {
      height: "360px",
    },
  },
  cardroot: {
    "& .details": {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      "&:hover .details": {
        display: "flex",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "& .details": {
        display: "flex",
      },
    },
    "&:hover .poster": {
      filter: "blur(2px)",
    },
  },
  scrollable: {
    //    overflow: "hidden",
    overflowY: "auto",
    paddingTop: "10px",
    height: (props) => {
      if (props.isMobile) return "calc(79vh - 10px - 10px - 24px)";
      if (props.isExclusiveTablet)
        return "calc(79vh - 10px - 80px - 80.012px - 68.8px - 100.9px)";
      return "calc(79vh - 10px - 80.012px - 68.8px - 97.98px)";
    },
    marginTop: "0px",
    "&::-webkit-scrollbar": {
      width: "12px",
    },
    "&::-webkit-scrollbar-track": {
      background: theme.palette.background.paper,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "10px",
      border: `3px solid ${theme.palette.background.paper}`,
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const pkgBaseUrl = pkgJson.proxy;
axios.defaults.baseURL = pkgBaseUrl;
const apiver = "api/v3";
const basicStartYear = 1895;
const defaultRoleYear = 1990;
const yearValues = [defaultRoleYear, currentYear];

const ratingMarks = [
  { value: 0, label: <strong>0</strong> },
  { value: 2, label: "2" },
  { value: 4, label: "4" },
  { value: 6, label: "6" },
  { value: 8, label: "8" },
  { value: 10, label: <strong>10</strong> },
];

const votesMarks = [
  { value: 0, label: <strong>0</strong> },
  { value: 400000, label: <small>40</small> },
  { value: 800000, label: <small>80</small> },
  { value: 1200000, label: <small>120</small> },
  { value: 1600000, label: <small>160</small> },
  {
    value: 2000000,
    label: (
      <strong>
        <small>200</small>
      </strong>
    ),
  },
];

// Json Data
const genreList = [
  "Action",
  "Adult",
  "Adventure",
  "Animation",
  "Biography",
  "Comedy",
  "Crime",
  "Documentary",
  "Drama",
  "Family",
  "Fantasy",
  "Film-Noir",
  "Game-Show",
  "History",
  "Horror",
  "Music",
  "Musical",
  "Mystery",
  "News",
  "Reality-TV",
  "Romance",
  "Sci-Fi",
  "Short",
  "Sport",
  "Talk-Show",
  "Thriller",
  "War",
  "Western",
];

const movieList = [
  "movie",
  "short",
  "tvEpisode",
  "tvMiniSeries",
  "tvMovie",
  "tvSeries",
  "tvShort",
  "tvSpecial",
  "video",
  "videoGame",
];

const autotypes = [
  { name: "Title", value: "title" },
  { name: "Actor", value: "name" },
];

const genres = genreList.map((m) => ({ name: m, value: m }));
const movie = movieList.map((m) => ({ name: m, value: m }));

// Utility Functions
const getWindowWidth = () => {
  return window.innerWidth;
};

const getWindowHeight = () => {
  return window.innerHeight;
};

const valuetext = (value) => {
  return `${value}`;
};

const escapeRegexCharacters = (str) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

const capitalize = (str, lowerRest = false) => {
  const capitalizeWord = (word, shouldCapitalize) =>
    shouldCapitalize
      ? word.charAt(0).toUpperCase() +
        (lowerRest ? word.slice(1).toLowerCase() : word.slice(1))
      : lowerRest
      ? word.toLowerCase()
      : word;

  const words = str.split(" ");
  
  const capitalizedString = words
    .map((word, index) => 
      // Capitalize the first and last words, others based on lowerRest
      capitalizeWord(word, index === 0 || index === words.length - 1)
    )
    .join(" ");

  //console.log("got back " + capitalizedString);
  return encodeURIComponent(capitalizedString);
};


const renameKey = (origKey, newKey, objList) => {
  const newList = objList.map((obj) => {
    obj[newKey] = obj[origKey];
    delete obj[origKey];
    return obj;
  });
  return newList;
};

const addUrl = (objList) => {
  const newList = objList.map((obj) => {
    const newUrl = `${apiver}/image/${obj.id}`;
    return { ...obj, url: newUrl };
  });
  return newList;
};

const getData = (path, outerData) => {
  return axios.post(`${apiver + path}`, JSON.stringify(outerData));
};

const Search = ({ imageDimensions }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const classes = gridCardStyles({ isMobile, isTablet });

  // all states
  const [autotype, setAutotype] = useState("title");
  const [rating, setRating] = useState("7");
  const [votes, setVotes] = useState("50000");
  const [posters, setPosters] = useState([]);
  const [nameSuggestions, setNameSuggestions] = useState([]);
  const [titleSuggestions, setTitleSuggestions] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState(["Action", "Adventure"]);
  const [textValue, setTextValue] = useState("");
  const [selectedTitleType, setSelectedTitleType] = useState(["movie"]);
  const [adult, setAdult] = useState(false);
  const [years, setYears] = useState(yearValues);
  const [showSearch, setShowSearch] = useState(false);
  const [page, setPage] = useState(1);
  const [showDisabled, setShowDisabled] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [lastTitleSelection, setLastTitleSelection] = useState({
    primaryTitle: "",
    isModified: false,
    selected: false,
  });
  const [lastQuery, setLastQuery] = useState(roles["default"]);
  const [isAppending, setIsAppending] = useState(false);
  const [width, setWidth] = useState(document.documentElement.clientWidth);
  const [searching, setSearching] = useState(false);

  const lastQueryRef = useRef(lastQuery);
  const hasMoreDataRef = useRef(hasMoreData);

  // For building the API path while infinite scrolling
  const makePath = (pg, rtng, cardObj) => {
    const windowWidth = getWindowWidth();
    const windowHeight = getWindowHeight();
    const cardWidth = cardObj.width;
    const cardHeight = cardObj.height;
    return (
      "/" +
      autotype +
      "/" +
      pg +
      "/" +
      rtng +
      "?ws=" +
      windowWidth +
      "&wh=" +
      windowHeight +
      "&cs=" +
      cardWidth +
      "&ch=" +
      cardHeight +
      "&off=0"
    );
  };

  /* All Handler functions */

  // For Processing the API response
  const handleResponse = (res) => {
    const remainingCount = parseInt(res.headers["x-remaining-count"]);
    if (res.data.length === 0 && remainingCount === 0) {
      setHasMoreData(false); // No more data to fetch
      if (page === 1) setPosters([]);
    } else if (res.data.length !== 0 && remainingCount === 0) {
      processData(res.data);
      setHasMoreData(false);
    } else processData(res.data);
  };

  // Note: Depending on browser...   setWidth(window.innerWidth)
  const handleResize = () => setWidth(document.documentElement.clientWidth);

  const handleShowFilmsClick = (e) => {
    setShowSearch(false);
    setSearching(true);
    onSubmit(e);
  };

  // Triggering the next page fetch
  const triggerDataFetch = () => {
    setPage((prevPage) => prevPage + 1);
  };

  // Fetching data for the page Using APIs
  let loadingData = false;
  const fetchDataForPage = async (page, initialLoad = false) => {
    if (loadingData) return;

    loadingData = true;
    setIsLoading(true);
    setIsAppending(!initialLoad);

    try {
      await new Promise((resolve) => {
        setTextValue((currentValue) => {
          resolve(currentValue);
          return currentValue;
        });
      });

      const prevRating = await new Promise((resolve) => {
        setRating((currentRating) => {
          resolve(currentRating);
          return currentRating;
        });
      });

      const path = makePath(page, prevRating, imageDimensions);
      const dataQuery = lastQueryRef.current;
      const stQuery = (() => {
        const { query, searchType, ...rest } = dataQuery;

        if (autotype === "title") {
          if (lastTitleSelection.primaryTitle !== "") {
            return {
              ...dataQuery,
              searchType: lastTitleSelection.isModified ? "regex" : "exact",
              query: lastTitleSelection.primaryTitle,
            };
          } else {
            return { ...rest };
          }
        } else {
          return query === "" ? { ...rest } : dataQuery;
        }
      })();

      setLastTitleSelection({
        primaryTitle: "",
        isModified: false,
        selected: false,
      });

      const response = await getData(path, stQuery);
      handleResponse(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
      loadingData = false;
      setTextValue("");
      setSearching(false);
    }
  };

  useEffect(() => {
    if (page > 1) {
      // To avoid fetching on initial render
      fetchDataForPage(page);
    }
  }, [page]);

  /* All useEffects */
  useEffect(() => {
    setYears(roles["default"].year);
    const path = makePath(page, rating, imageDimensions);
    getData(path, roles["default"]).then((res) => {
      processData(res.data);
    });
  }, [imageDimensions]);

  useEffect(() => {
    if (showSearch) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showSearch]);

  useEffect(() => {
    lastQueryRef.current = lastQuery;
  }, [lastQuery]);

  useEffect(() => {
    hasMoreDataRef.current = hasMoreData;
  }, [hasMoreData]);

  // This useEffect is for the window resize event.
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const autoTypeOptions = () => {
    return autotypes.map((k, index) => (
      <MenuItem key={index} value={k.value}>
        {k.name}
      </MenuItem>
    ));
  };

  const movieTypeOptions = () => {
    return movie.map((k, index) => (
      <MenuItem key={k.name} value={k.value}>
        <Checkbox checked={selectedTitleType.indexOf(k.name) > -1} />
        <ListItemText primary={k.name} />
      </MenuItem>
    ));
  };

  const genreTypeOptions = () => {
    return genres.map((k, index) => (
      <MenuItem key={k.name} value={k.value}>
        <Checkbox checked={selectedGenres.indexOf(k.name) > -1} />
        <ListItemText primary={k.name} />
      </MenuItem>
    ));
  };

  const processData = (data) => {
    if (data.length !== 0) {
      getPosters(addUrl(renameKey("_id", "id", data)));
    } else {
      setIsAppending((prevIsAppending) => {
        if (!prevIsAppending) setPosters([]);
        return prevIsAppending;
      });
    }
  };

  const getPosters = async (posterData) => {
    setIsAppending((prevIsAppending) => {
      setPosters((prevPosters) => {
        const combinedPosters = prevIsAppending
          ? [...prevPosters, ...posterData]
          : posterData;
        const uniquePostersMap = new Map();
        combinedPosters.forEach((poster) => {
          uniquePostersMap.set(poster.id, poster);
        });
        const uniquePosters = Array.from(uniquePostersMap.values());
        return uniquePosters;
      });
      return prevIsAppending;
    });
  };

  const fetchAutoValue = (val) => {
    setRating((prevRating) => {
      const path =
        autotype === "title"
          ? `/autotitle/${val}/${prevRating}`
          : `/autoname/${val}/${prevRating}`;
      const getQuery = lastQueryRef.current;
      getData(path, getQuery).then((res) => {
        if (autotype === "title") {
          setTitleSuggestions(res.data);
        } else setNameSuggestions(res.data);
      });
      return prevRating;
    });
  };

  const onTitleActorFetchRequested = (value) => {
    fetchAutoValue(escapeRegexCharacters(capitalize(value, true)));
  };

  const titleInputProps = {
    placeholder: `Type ${autotype}`,
    value: textValue,
  };

  const nameInputProps = {
    placeholder: `Type actor`,
    value: textValue,
  };

  const onSelectAdult = (mtPicked) => {
    setAdult(mtPicked);
    setLastQuery((prevState) => ({
      ...prevState,
      isAdult: mtPicked,
    }));
  };

  const onSelectAutotype = (autoPicked) => {
    const shouldDisable = autoPicked === "name";
    setAutotype(autoPicked);
    setShowDisabled(shouldDisable);
    setPage(1);
    setTextValue("");
  };

  const onSelectedTitleType = (titleType) => {
    const newTitleType =
      titleType[0] === "Movie Type"
        ? titleType.slice(1)
        : titleType.length === 0
        ? ["Movie Type"]
        : titleType;
    setSelectedTitleType(newTitleType);
    if (newTitleType[0] !== "Movie Type") {
      setLastQuery((prevState) => ({
        ...prevState,
        titleType: newTitleType,
      }));
    } else {
      setLastQuery((prevState) => {
        const { titleType, ...rest } = prevState;
        return rest;
      });
    }
  };

  const onSelectedGenre = (genre) => {
    setIsAppending(false);
    const newGenres =
      genre[0] === "Genre"
        ? genre.slice(1)
        : genre.length === 0
        ? ["Genre"]
        : genre;
    setSelectedGenres(newGenres);
    if (newGenres[0] !== "Genre") {
      setLastQuery((prevState) => ({
        ...prevState,
        genre: newGenres,
      }));
    } else {
      setLastQuery((prevState) => {
        const { genre, ...rest } = prevState;
        return rest;
      });
    }
  };

  const onSelectYear = (event, yrPicked) => {
    setIsAppending(false);
    setYears(yrPicked);
    setLastQuery((prevState) => ({ ...prevState, year: yrPicked }));
  };

  const onChangeRating = (event, ratingPicked) => {
    setIsAppending(false);
    setRating(ratingPicked);
  };

  const onChangeVotes = (event, votesPicked) => {
    setIsAppending(false);
    setVotes(votesPicked);
    setLastQuery((prevState) => ({
      ...prevState,
      votes: votesPicked,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const canQuery = autotype === "title" ? years.length > 0 : years.length > 0;
    if (canQuery) {
      setPage(1);
      setHasMoreData(true);
      fetchDataForPage(1, true);
    }
  };

  const RenderPosters = () => {
    if (posters.length > 0) {
      return (
        <Grid item container direction="row" spacing={3}>
          {posters.map((poster, idx) => (
            <Grid
              data-test-id="render grid"
              key={idx}
              item
              xs={6}
              //              xs={posters.length === 1 ? 12 : 6}
              sm={4}
              md={3}
              lg={2}
              className={classes.cardroot}
              //              sx={{ width: isDesktop ? "200px" : "100%", height: isDesktop ? "300px" : "auto" }}
            >
              <Box
                data-test-id="box grid"
                sx={{
                  position: "relative",
                  overflowY: "hidden",
                  width: "100%",
                  paddingBottom: "150%",
                  height: {
                    xs: "200px", // Height for extra-small screens
                    sm: "250px", // Height for small screens
                    md: "300px", // Height for medium screens
                    lg: "300px", // Height for large screens
                  },
                  //                width: isDesktop ? "200px" : "100%",
                  //                 height: isDesktop ? "300px" : "auto"
                }}
              >
                <Link
                  underline="hover"
                  href={"https://imdb.com/title/" + poster.id}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <Card
                    raised
                    key={poster.originalTitle}
                    data-test-id="cardraised"
                    style={{ position: "relative" }}
                  >
                    <CardMedia
                      width="100%"
                      component="img"
                      sx={{ objectFit: "contain" }}
                      src={`${poster.url}`}
                      alt={poster.originalTitle}
                      className="poster"
                    />
                    <Box
                      sx={{
                        borderRadius: "10px",
                        backgroundColor: "rgba(0, 0, 0, 0.4)",
                        display: "none",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "fit-content",
                        height: "auto",
                        top: 5,
                        right: 5,
                        padding: "4px 8px 4px 8px",
                        position: "absolute",
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "white",
                      }}
                      className="details"
                    >
                      {poster.averageRating}
                      <StarIcon fontSize="10px" />
                    </Box>
                    <Box
                      sx={{
                        borderRadius: "10px",
                        backgroundColor: "rgba(0, 0, 0, 0.4)",
                        display: "none",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: "fit-content",
                        height: "auto",
                        bottom: 3,
                        left: 5,
                        padding: "5px",
                        position: "absolute",
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "white",
                      }}
                      className="details"
                    >
                      {poster.genresList[0]}
                    </Box>
                  </Card>
                </Link>
              </Box>
              <Box>
                <Typography variant="subtitle2">
                  <Link
                    underline="hover"
                    href={"https://imdb.com/title/" + poster.id}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    {poster.originalTitle.slice(0, 20)}
                  </Link>
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ color: "theme.text.primary" }}
                >
                  {poster.startYear}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      );
    }
  };

  const renderNoData = () => {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SentimentDissatisfiedIcon color="disabled" fontSize="large" />
        <Typography variant="h6" gutterBottom>
          No Results Found
        </Typography>
        <Typography variant="subtitle1">
          Try adjusting your filters or new search.
        </Typography>
      </Box>
    );
  };

  const filtersComponents = [
    <DebouncedSelect
      fetchOptions={onTitleActorFetchRequested}
      autotype={autotype}
      onSelectAutotype={onSelectAutotype}
      autoTypeOptions={autoTypeOptions}
      namesuggestions={nameSuggestions}
      titlesuggestions={titleSuggestions}
      titleInputProps={titleInputProps}
      nameInputProps={nameInputProps}
      setLastTitleSelection={setLastTitleSelection}
      setIsAppending={setIsAppending}
      setShowDisabled={setShowDisabled}
      lastTitleSelection={lastTitleSelection}
      textValue={textValue}
      setTextValue={setTextValue}
      setLastQuery={setLastQuery}
    />,
    <MultiSelectType
      selectedTitleType={selectedTitleType}
      onSelectedTitleType={onSelectedTitleType}
      movieTypeOptions={movie}
      label="Movie Type"
    />,
    <MultiSelectType
      selectedTitleType={selectedGenres}
      onSelectedTitleType={onSelectedGenre}
      movieTypeOptions={genres}
      label="Genre"
    />,
    <RatingSlider
      valueText={valuetext}
      marks={ratingMarks}
      curRating={rating}
      ratingChange={onChangeRating}
    />,
    <YearSlider
      years={years}
      basicStartYear={basicStartYear}
      currentYear={currentYear}
      onSelectYear={onSelectYear}
      valuetext={valuetext}
    />,
    <VoteSlider
      votes={votes}
      marks={votesMarks}
      valuetext={valuetext}
      onChangeVotes={onChangeVotes}
    />,
    <AdultToggle adult={adult} onSelectAdult={onSelectAdult} />,
    <ShowFilmsBtn onClick={handleShowFilmsClick} disabled={showDisabled} />,
  ];

  // For Rendering the Mobile Filters
  const RenderMobileFilters = () => {
    const firstGroup = filtersComponents
      .slice(0, 3)
      .map((Component, index) => (
        <React.Fragment key={index}>{Component}</React.Fragment>
      ));

    const secondGroup = filtersComponents
      .slice(3, 6)
      .map((Component, index) => (
        <React.Fragment key={index + 3}>{Component}</React.Fragment>
      ));

    const thirdGroup = filtersComponents
      .slice(6, 8)
      .map((Component, index) => (
        <React.Fragment key={index + 3}>{Component}</React.Fragment>
      ));

    return (
      <>
        <Grid item xs={6} sm={4}>
          {firstGroup}
        </Grid>
        <Grid item xs={6} sm={4}>
          {secondGroup}
        </Grid>
        <Grid item xs={12} sm={4}>
          {thirdGroup}
        </Grid>
      </>
    );
  };

  // For Rendering the Desktop Filters
  const RenderDesktopFilters = () => {
    const titleDrop = filtersComponents[0];
    const movieDrop = filtersComponents[1];
    const actionDrop = filtersComponents[2];
    const imdbSlider = filtersComponents[3];
    const yearsSlider = filtersComponents[4];
    const votesSlider = filtersComponents[5];
    const adultCheck = filtersComponents[6];
    const submitBtn = filtersComponents[7];

    return (
      <Grid
        container
        spacing={6}
        className={classes.filterContainer}
        justifyContent={"space-between"}
      >
        <Grid item xs={3}>
          <Grid container direction="column" spacing={2} paddingBottom={5}>
            <Grid item width={"100%"}>
              {titleDrop}
            </Grid>
            <Grid item width={"100%"}>
              {movieDrop}
            </Grid>
            <Grid item width={"100%"}>
              {actionDrop}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid container direction="column" spacing={2}>
            <Grid item>{imdbSlider}</Grid>
            <Grid item>{yearsSlider}</Grid>
            <Grid item>{votesSlider}</Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid container direction="column" spacing={2}>
            <Grid item>{adultCheck}</Grid>
            <Grid item>{submitBtn}</Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {searching && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <Circles color="#7D8C91" height={80} width={80} />
        </div>
      )}
      <Container
        maxWidth="xl"
        data-test-id="maxwidth"
        sx={{ overflowX: "hidden" }}
      >
        {/* Note: mobile/tablet view */}
        {width < theme.breakpoints.values.md ? (
          <Box sx={{ flexGrow: 1, paddingTop: "10px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItem: "center",
                justifyContent: "flex-start",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => setShowSearch((prev) => !prev)}
                startIcon={<SearchIcon />}
              >
                Search
              </Button>
            </Box>
            {showSearch && (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{
                  position: "fixed",
                  top: "94px",
                  width: "100%",
                  zIndex: 1000,
                  backgroundColor: "#fff",
                }}
              >
                {RenderMobileFilters()}
              </Grid>
            )}
            <InfiniteScroll
              dataLength={posters.length}
              next={triggerDataFetch}
              hasMore={hasMoreData}
              hasChildren={true}
              loader={<CircleLoader />}
              height={"calc(76vh - 15px - 15px - 24px)"}
              className={classes.scrollable}
            >
              {posters.length ? (
                <Grid container direction="row">
                  {RenderPosters()}
                </Grid>
              ) : (
                <Grid height="100%" direction="row" container>
                  {renderNoData()}
                </Grid>
              )}
            </InfiniteScroll>
          </Box>
        ) : (
          <Box
            data-test-id="top box desktop"
            sx={{ flexGrow: 1, paddingTop: "10px", overflowX: "hidden" }}
          >
            {/* Note: desktop view */}
            <Grid container direction="column">
              {RenderDesktopFilters()}
              <InfiniteScroll
                dataLength={posters.length}
                next={triggerDataFetch}
                hasMore={hasMoreData}
                hasChildren={true}
                loader={<CircleLoader />}
                height={
                  "calc(76vh - 10px - 44px - 44.012px - 68.8px - 100.9px)"
                }
                className={classes.scrollable}
                scrollableTarget="scrollableDiv"
              >
                {posters.length ? (
                  <Grid container>{RenderPosters()}</Grid>
                ) : (
                  <Grid height="100%" container>
                    {renderNoData()}
                  </Grid>
                )}
              </InfiniteScroll>
            </Grid>
          </Box>
        )}
      </Container>
    </>
  );
};

export default Search;
