import React from 'react'
import { styled } from "@mui/material/styles"
import Box from '@mui/material/Box'
import CustomSlider from "./CustomSliderStyles"
import Typography from '@mui/material/Typography'

const StyledBox = styled(Box)(({ theme }) => ({
    width: "100%",
    margin: "5px",
    boxSizing: "border-box",
    [theme.breakpoints.down('1020')]: {
      width: "100%",
    },
    [theme.breakpoints.down('899')]: {
      width: "65%",
    },
}))

const VoteSlider = (props) => {
  const { votes, marks, valuetext, onChangeVotes } = props
  return (
    <StyledBox id="votes-selector">
      <Typography id="votes-slider">Votes ({votes / 1000}) K</Typography>
      <CustomSlider
        aria-labelledby="discrete-slider-custom"
        getAriaValueText={valuetext}
        defaultValue={50000}
        min={0}
        max={2000000}
        step={50000}
        marks={marks}
        track="inverted"
        valueLabelDisplay="off"
        onChange={onChangeVotes}
      />
    </StyledBox>
  )
}

export default VoteSlider
