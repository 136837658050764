import React from 'react'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import { makeStyles } from '@mui/styles'

const GetSingleImageDim = ({ imgUrl, onImageLoaded }) => {
  const offScreenStyles = makeStyles((theme) => ({
    offScreen: {
      position: 'absolute',
      left: '-9999px',
      maxWidth: '230px',
    },
  }))
  const classes = offScreenStyles()

  const onImgLoad = ({ target: img }) => {
    const { offsetHeight, offsetWidth } = img
    onImageLoaded(offsetHeight, offsetWidth)
  }

  return (
    // <div style={classes.offScreen}>
    <Card raised>
      <CardMedia
        width="100%"
        component="img"
        onLoad={onImgLoad}
        sx={{
          height: 0,
          paddingTop: '56.25%', // 16:9
          objectFit: 'contain',
        }}
        src={imgUrl}
        alt="Image for Dimension Calculation"
        className={classes.offScreen}
      />
    </Card>
    // </div>
  )
}

export default GetSingleImageDim
