import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Home from "./pages/Home"
import About from "./pages/About"
import Contact from "./pages/Contact"
import { lightTheme } from "./theme/theme"
import { Helmet } from "react-helmet"
import CustomNav from "./components/CustomNav"
import Footer from "./components/Footer"
import PageLayout from "./pages/PageLayout"
import { styled /* useTheme */ } from "@mui/material/styles"
import { ThemeProvider, CssBaseline, Box } from "@mui/material"

const AppWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
})

const ContentWrapper = styled(Box)({
  flex: "1 0 auto",
})

const App = () => {
  const isSearchable = process.env.REACT_APP_SEARCHABLE === "true";
  //const theme = useTheme()
  //console.log(theme)

  const StyledHome = styled(Home)(({ theme }) => ({
    searchContent: {
      minHeight: "74vh",
      maxHeight: "79vh",
    },
  }))

  const HomeWrapper = () => (
    <div>
      <StyledHome />
    </div>
  )

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      {/* <ThemeInspector /> */}
      <Router>
        {!isSearchable && (
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        )}
        <AppWrapper>
        <CustomNav />
        <ContentWrapper>
          <Routes>
            <Route exact path="/" element={<HomeWrapper />} />
            <Route
              exact
              path="/about"
              element={
                <PageLayout>
                  <About />
                </PageLayout>
              }
            />
            <Route
              exact
              path="/contact"
              element={
                <PageLayout>
                  <Contact />
                </PageLayout>
              }
            />
          </Routes>
        </ContentWrapper>
        <Footer content={null} />
        </AppWrapper>
      </Router>
    </ThemeProvider>
  )
}

export default App
